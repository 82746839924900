<template>
  <DelayedTransitionGroup>
    <div
      :key="'error404'"
      class="card">
      <div class="card-body">
        <h1
          v-t="'error404.title'"
          class="display-1" />
        <h3 v-t="'error404.page_not_found'" />

        <CButton
          v-test="{ id: 'homepage-link' }"
          @click="$router.push('/')">
          {{ $t('error404.back_to_homepage') }}
        </CButton>
      </div>
    </div>

    <Error404Footer
      :key="'Error404Footer'"
      v-test="{ id: 'footer' }" />
  </DelayedTransitionGroup>
</template>

<script>
import CButton from '@/components/CButton';
import CompanyFooter from '@/components/CompanyFooter';

export default {
  name: 'Error404',
  components: {
    Error404Footer: CompanyFooter,
    CButton,
  },
};
</script>
